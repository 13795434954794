@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

.gradient {
  background: radial-gradient(ellipse at left, rgba(0, 38, 255, 0.2549019608), transparent), radial-gradient(ellipse at right, rgba(0, 20, 204, 0.4392156863), transparent);
  transition: 0.3s;
}

main {
  padding: 140px 20% 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
main i {
  font-style: normal;
  color: #00305f;
  font-weight: 600;
}
@media (max-width: 1600px) {
  main {
    padding: 140px 10% 20px;
  }
}
@media (max-width: 1200px) {
  main {
    padding: 140px 20px 20px;
  }
}

nav {
  margin: 20px 20%;
  border-radius: 15px;
  height: 100px;
  background-color: #00305f;
  display: flex;
  flex-direction: row;
  position: fixed;
  width: calc(100vw - 40%);
  z-index: 9999;
}
nav a {
  color: white;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  border-radius: 15px;
  padding: 10px 20px;
  transition: 0.2s;
  text-decoration: none;
}
@media (max-width: 680px) {
  nav a {
    display: none;
  }
}
nav a:hover {
  background-color: #004183;
  transition: 0.2s;
}
nav img {
  height: 100%;
  margin-right: auto;
  padding: 10px;
}
nav button {
  background-color: unset;
  color: white;
  border: 0;
  margin-right: 10px;
  width: 64px;
  height: 48px;
  padding: 10px;
  margin-top: auto;
  margin-bottom: auto;
  display: none;
}
nav button svg {
  width: 100%;
  height: 100% !important;
}
@media (max-width: 680px) {
  nav button {
    display: flex;
  }
}
@media (max-width: 1600px) {
  nav {
    margin: 20px 10%;
    width: calc(100vw - 20%);
  }
}
@media (max-width: 1200px) {
  nav {
    margin: 20px 20px;
    width: calc(100vw - 40px);
  }
}
@media (max-width: 480px) {
  nav {
    margin: 20px;
  }
}

footer {
  background-color: #00305f;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin-top: auto;
}
footer img {
  height: 20vw;
  max-height: 120px;
  margin-top: auto;
  margin-bottom: auto;
}
footer p {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 50px;
  color: white;
}
footer p:last-of-type {
  margin-left: auto;
}

.card {
  background-color: rgba(245, 245, 245, 0.5568627451);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 10px;
}
.card h1 {
  color: #00305f;
  text-align: center;
  font-size: 220%;
}
.card p {
  margin-top: 10px;
  text-align: center;
  font-size: 120%;
}

.landing {
  flex-direction: row;
}
.landing .left {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.landing .left img {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
@media (max-width: 960px) {
  .landing .left {
    width: 100%;
    order: 2;
  }
}
.landing .right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.landing .right h1 {
  color: #00305f;
  text-align: center;
  font-size: 220%;
  border: 0;
}
.landing .right p {
  text-align: center;
  margin-bottom: 20px;
  font-size: 120%;
}
.landing .right .storeBadges {
  display: flex;
  flex-direction: row;
  max-height: 70px;
  justify-content: center;
}
.landing .right .storeBadges a {
  max-height: 70px;
  height: 10vw;
  margin: 0 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s;
}
.landing .right .storeBadges a img {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.landing .right .storeBadges a:hover {
  filter: brightness(80%);
  transition: 0.2s;
}
@media (max-width: 960px) {
  .landing .right {
    width: 100%;
  }
}
@media (max-width: 960px) {
  .landing {
    flex-direction: column;
  }
}

.faqCard h2 {
  color: #00305f;
  margin-bottom: 10px;
}
.faqCard p {
  margin-top: 10px;
  text-align: left;
}

.featureCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 680px) {
  .featureCard {
    flex-direction: column;
  }
}
.featureCard .left, .featureCard .right {
  width: calc(50% - 5px);
}
@media (max-width: 1200px) {
  .featureCard .left, .featureCard .right {
    width: 100%;
  }
}
.featureCard .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.featureCard .right {
  display: flex;
  flex-direction: row;
}
.featureCard .right img {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 80%;
  max-width: 100%;
}
@media (max-width: 1200px) {
  .featureCard .right img {
    max-width: 70%;
  }
}
@media (max-width: 1200px) {
  .featureCard .right {
    margin-top: 20px;
  }
}

.polityka p {
  text-align: left;
  margin-bottom: 10px;
}
.polityka li {
  list-style-position: inside;
  font-size: 110%;
}

.mobilemenu {
  display: none;
  transition: transform 0.4s ease-in-out !important;
  backface-visibility: hidden;
  position: fixed;
  height: 100vh;
  background-color: #00305f;
  width: 50%;
  padding: 140px 20px 20px 20px;
  flex-direction: column;
  color: white;
}
.mobilemenu a {
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  color: white;
  width: 100%;
  padding: 20px;
  transition: 0.2s;
}
.mobilemenu a:hover {
  background-color: #004183;
  transition: 0.2s;
  border-radius: 15px;
}
@media (max-width: 960px) {
  .mobilemenu {
    display: flex;
  }
}
.mobilemenu #navCredits {
  margin-top: auto;
}

.closed {
  transform: translateX(100vw);
}

.opened {
  display: flex;
  transform: translateX(100%);
}/*# sourceMappingURL=index.css.map */