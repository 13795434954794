@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}

$bp : (mobile : 480px,
    navigationBreak: 680px,
    tablet : 960px,
    badges : 1200px,
    desktop : 1600px,
);

@mixin query($display) {
    @each $key, $value in $bp {

        //  defining max-width
        @if ($display ==$key) {
            @media (max-width: $value) {
                @content;
            }
        }
    }
}

// $main: #00305f;
// $cardBG: #f5f5f5;
$main: #00305f;
$cardBG: #f5f5f58e;

.gradient {
    background: radial-gradient(ellipse at left, #0026ff41, transparent),
        radial-gradient(ellipse at right, #0014cc70, transparent);
    transition: 0.3s;
}

main {
    padding: 140px 20% 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    i {
        font-style: normal;
        color: $main;
        font-weight: 600;
    }

    @include query(desktop) {
        padding: 140px 10% 20px;
    }

    @include query(badges) {
        padding: 140px 20px 20px;
    }
}

nav {
    margin: 20px 20%;
    border-radius: 15px;
    height: 100px;
    background-color: $main;
    display: flex;
    flex-direction: row;
    position: fixed;
    width: calc(100vw - 40%);
    z-index: 9999;

    a {
        color: white;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px;
        border-radius: 15px;
        padding: 10px 20px;
        transition: 0.2s;
        text-decoration: none;

        @include query(navigationBreak) {
            display: none;
        }
    }

    a:hover {
        background-color: #004183;
        transition: 0.2s;
    }

    img {
        height: 100%;
        margin-right: auto;
        padding: 10px;
    }

    button {
        background-color: unset;
        color: white;
        border: 0;
        margin-right: 10px;
        width: 64px;
        height: 48px;
        padding: 10px;
        margin-top: auto;
        margin-bottom: auto;
        display: none;

        svg {
            width: 100%;
            height: 100% !important;
        }

        @include query(navigationBreak) {
            display: flex;
        }
    }

    // @include query(tablet) {
    //     width: calc(100vw - 55px);
    // }

    // @include query(tablet) {
    //     width: calc(100vw - 40px);
    // }

    @include query(desktop) {
        margin: 20px 10%;
        width: calc(100vw - 20%);
    }

    @include query(badges) {
        margin: 20px 20px;
        width: calc(100vw - 40px);
    }

    @include query(mobile) {
        margin: 20px;
        // width: calc(100vw - 55px);
    }


}

footer {
    background-color: $main;
    // height: 200px;
    // margin: 20px 20px 0px 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin-top: auto;

    img {
        height: 20vw;
        max-height: 120px;
        margin-top: auto;
        margin-bottom: auto;
    }

    p {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 50px;
        color: white;
    }

    p:last-of-type{
        margin-left: auto;
    }
}

.card {
    background-color: $cardBG;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 20px;

    // min-height: 20vh;
    margin-bottom: 10px;

    h1 {
        color: $main;
        text-align: center;
        // margin-bottom: 10px;
        font-size: 220%;

        // border-bottom: 4px solid $main;
    }

    p {
        margin-top: 10px;
        text-align: center;
        font-size: 120%;
    }
}

.landing {
    flex-direction: row;

    .left {
        width: 50%;
        display: flex;
        flex-direction: column;
        // padding: 20px;

        img {
            width: 70%;
            // height: 70%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;
        }

        @include query(tablet) {
            width: 100%;
            order: 2;
        }
    }

    .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding: 20px;

        h1 {
            color: $main;
            text-align: center;
            // margin-bottom: 10px;
            font-size: 220%;
            border: 0;
        }

        p {
            text-align: center;
            margin-bottom: 20px;
            font-size: 120%;
        }

        .storeBadges {
            // width: 100%;
            display: flex;
            flex-direction: row;
            max-height: 70px;
            justify-content: center;

            a {
                max-height: 70px;
                height: 10vw;
                // width: 50%;
                margin: 0 5px;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                transition: 0.2s;

                // width: calc(50% - 10px);

                img {
                    height: 100%;
                    // margin-top: auto;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            a:hover {
                filter: brightness(80%);
                transition: 0.2s;
            }

            // img{
            //     width: calc(100% - 10px);
            // }
        }

        @include query(tablet) {
            width: 100%;
        }
    }

    @include query(tablet) {
        flex-direction: column;
    }
}

.faqCard {

    h2 {
        color: $main;
        margin-bottom: 10px;
    }

    p {
        margin-top: 10px;
        text-align: left;
    }
}

.featureCard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include query(navigationBreak) {
        flex-direction: column;
    }

    .left, .right{
        width: calc(50% - 5px);

        @include query(badges) {
            width: 100%;
        }
    }

    .left{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .right{
        display: flex;
        flex-direction: row;
        img{
            margin-left: auto;
            margin-right: auto;
            margin-top: auto;
            margin-bottom: auto;
            height: 80%;
            max-width: 100%;

            @include query(badges) {
                max-width: 70%;
            }
        }

        @include query(badges) {
            margin-top: 20px;
        }
    }
}

.polityka{
    p{
        text-align: left;
        margin-bottom: 10px;
    }

    li{
        list-style-position: inside;
        font-size: 110%;
    }
}

.mobilemenu{
    display: none;
    transition: transform 0.4s ease-in-out !important;
    backface-visibility: hidden;
    position: fixed;
    height: 100vh;
    background-color: $main;
    width: 50%;
    padding: 140px 20px 20px 20px;
    flex-direction: column;
    color: white;

    a{
        margin-left: auto;
        margin-right: auto;
        text-decoration: none;
        color: white;
        width: 100%;
        padding: 20px;
        transition: 0.2s;
    }

    a:hover{
        background-color: #004183;
        transition: 0.2s;
        border-radius: 15px;
    }

    @include query(tablet) {
        display: flex;
    }

    #navCredits{
        margin-top: auto;
    }
}

.closed{
    transform: translateX(100vw);
}

.opened{
    display: flex;
    transform: translateX(100%);
}